.EditorParent {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
}

.EditorParent .titleBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  background: #8e44ad;
  padding: 10px 10vw;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.EditorParent .titleBar .mainText {
  font-size: 1.6em;
}

.EditorParent .titleBar .iconLinks {
  margin-top: 3px;
}

.EditorParent .titleBar .iconLinks img {
  display: inline-block;
  height: 30px;
  width: auto;
}

.EditorParent .Footer {
  display: flex;
  flex-diection: row;
  justify-content: space-between;
  box-sizing: border-box;
  background: #2980b9;
  padding: 5px 10vw;
}

.EditorParent .Footer a {
  color: #fff;
}

.EditorParent .Editor {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin: 0 10vw;
  height: calc(100% - 88px);
}

@media only screen and (max-width: 1500px) {
  .EditorParent .Editor {
    margin: 0 30px;
  }
}

.EditorParent .Editor .Nav {
  padding: 20px 0;
  width: 250px !important;
  display: flex;
  flex-direction: column;
}

.EditorParent .Editor .Nav button {
  width: 100%;
  font-size: 1.25em;
  background: none;
  border: none;
  padding: 10px 15px;
  margin: 5px 0;
  font-weight: 600;
  text-align: left;
  border-radius: 5px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.EditorParent .Editor .Nav .NavQA {
  height: 100%;
}

.EditorParent .Editor .Nav .NavQA .disabled {
  background: rgba(255, 255, 255, 0.05) !important;
  color: rgba(255, 255, 255, 0.15);
  cursor: not-allowed;
}

.EditorParent .Editor .Nav .NavQP button {
  margin-top: 20px;
  background: #16a085;
}

.EditorParent .Editor .Nav .NavQP button span {
  padding-right: 5px;
}

.EditorParent .Editor .loadingDisplay {
  display: block;
  text-align: center;
  padding: 50px 0 0 0;
  position: relative;
  max-height: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  font-size: 1.5em;
}

.EditorParent .Editor .loadingDisplay p:first-child {
  font-size: 2em;
  margin-bottom: 10px;
}

.EditorParent .Editor .Page {
  position: relative;
  padding: 25px 25px 0 45px;
  max-height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  flex-grow: 1;
}

.EditorParent .Editor .Page .mainText {
  font-weight: 600;
  font-size: 3em;
  margin-bottom: 20px;
}

.EditorParent .Editor .Page .noteText,
.hintText {
  display: block;
  font-size: 1.15em;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 10px 15px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 30px;
}

.EditorParent .Editor .Page .sbm {
  margin-bottom: 10px !important;
}

.EditorParent .Editor .Page .noteText {
  border-left: solid 15px #f39c12;
}

.EditorParent .Editor .Page .hintText {
  border-left: solid 15px #2ecc71;
}

.EditorPageParent .EditableItem {
  display: block;
  background: rgba(255, 255, 255, 0.15);
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 30px;
}

.EditorPageParent .EditableItem p {
  font-size: 1.1em;
}

.EditorPageParent .EditableItem code {
  font-size: 1.15em;
}

.EditorPageParent .EditableItem .ItemTitle {
  font-size: 1.75em;
  font-weight: 600;
  margin-bottom: 5px;
}

.EditorPageParent .EditableItem .inputLabel {
  display: inline-block;
  margin: 15px 10px 0 0;
}

.EditorPageParent .EditableItem .nbx {
  display: block;
}

.EditorPageParent .EditableItem .selectItem {
  display: inline-block;
  min-width: 300px;
  color: #000;
  margin-top: 10px;
}

.EditorPageParent .EditableItem .fullW {
  width: 100%;
}

/* punish page */

.EditableItem .punishList {
  display: flex;
  flex-direction: column;
}

.punishList .punishItem,
.createItem {
  display: flex;
  flex-direction: row;
  background: rgba(255, 255, 255, 0.15);
  padding: 0;
  align-items: stretch;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.punishList .createItem {
  justify-content: center;
  padding: 15px 0;
  margin: 0 !important;
  cursor: pointer;
  font-size: 1.15em;
  font-weight: 600;
}

.punishList .punishItem .sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.5em;
  padding: 0 15px;
}

.punishList .punishItem .itemIndex {
  margin-right: 5px;
  border-right: solid 2px #dddddd33;
}

.punishList .punishItem .btnDeleteItem {
  margin-right: 5px;
  cursor: pointer;
}

.punishList .punishItem .main {
  flex-grow: 1;
  padding: 10px;
}

.punishList .punishItem .main span {
  display: inline-block;
  font-size: 1.15em;
  margin-top: 5px;
  margin-right: 10px;
}

.punishList .punishItem .main .selectItem {
  display: inline-block;
  min-width: 300px;
  color: #000;
  margin-top: 10px;
}

.punishList .punishItem .main input {
  border: none;
  padding: 3px 0;
  width: 50px;
  color: #333;
  text-align: center;
  border-radius: 3px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.95em;
  font-weight: 600;
}

.punishList .punishItem .smt {
  margin-top: 10px;
}

/* Editor Save */

.EditorPageParent .SaveParent {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 30px;
  font-size: 1.5em;
}

.EditorPageParent .SaveParent .MainText {
  font-size: 2.5em !important;
  font-weight: 600;
  margin-bottom: 30px;
}

.EditorPageParent .SaveParent .codeDisplay {
  display: inline-flex;
  font-size: 1.35em;
  background: rgba(255, 255, 255, 0.15);
  margin-bottom: 20px;
  border-radius: 10px;
  align-items: baseline;
}

.EditorPageParent .SaveParent .codeDisplay code {
  display: inline-block;
  padding: 5px 15px;
  margin: 0;
}

.EditorPageParent .SaveParent .codeDisplay button {
  display: inline-block;
  padding: 2px 10px 8px 10px;
  margin: 0;
  border: none;
  border-left: solid 2px #eee;
  background: none;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.85em;
  cursor: pointer;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.EditorPageParent .SaveParent .generalCodeBox {
  display: inline-block;
  padding: 2px 5px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
}
